/*==================== 04. For Providers Page CSS ====================*/

/*=========== provider slider =============*/

.provider__swiper {
  overflow: hidden !important;
  width: 100%;
}

.provider__revolutionize_container .slider-control-bottomcenter{
    display: none !important;
}

.provider__revolutionize__item {
  /* max-width: 400px;
    margin: auto; */
  background-color: #ffffff;
  max-width: 400px;
  min-height: 340px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  margin: auto;
}


.provider__revolutionize__text {
  color: var(--ms-third-color);
  line-height: 1.65em;
  font-family: var(--ms-second-font);
}

.provider__swiper__box {
  display: flex;
  align-self: end;
  position: static;
  z-index: -1;
}

.provider__container > h5 {
  display: none;
}

.provider__container {
  min-width: 300px !important;
  width: 90% !important;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.provider__container > h1:first-child {
  text-align: center;
  font-size: 30px !important;
}

/*----- 04.a. Banner Section -----*/
.ms-providers-banner {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px !important;
  flex-wrap: wrap;
  background: url("../Images/banner-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
  z-index: 1;
}
/*----- 04.b. Request Demo Section -----*/
.ms-request-demo-section {
  background: #f6f7f8;
  width: 100%;
  padding: 100px 10px;
}
.ms-form-layout button {
  width: 100%;
  height: 100%;
}

.form-control:focus {
  box-shadow: none !important;
}

form input {
  padding: 20px 20px !important;
  border-radius: 30px !important;
}

.transition {
  color: var(--ms-primary-color);
}

@media (min-width: 1210px) {
  form.ms-form-layout input {
    padding-right: 200px !important;
  }
  form.ms-form-layout button {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }

  .provider__container > h5 {
    display: block;
    margin-top: 15px;
    text-align: start;
    font-size: 25px !important;
  }

  .provider__swiper {
    position: absolute !important;
    bottom: 0px !important;
    right: 0px;
    width: 55% !important;
  }

  .provider__container > h1:first-child {
    font-size: 55px !important;
    text-align: start;
  }

  .provider__container > h5 {
    margin-top: 15px;
    text-align: start;
    font-size: 22px !important;
  }

  .provider__container {
    width: 55% !important;
    display: block !important;
    margin: 0px;
  }

  .ms-providers-banner {
    height: 90vh;
  }
}

@media screen and (min-width: 780px) and (max-width: 1200px) {
  form.ms-form-layout input {
    padding-right: 200px !important;
  }
  form.ms-form-layout button {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }

  .provider__container > h5 {
    margin-top: 15px;
    text-align: start;
    font-size: 16px !important;
  }

  .provider__swiper {
    position: absolute !important;
    z-index: -1;
    bottom: 0px !important;
    right: 0px;
    overflow: hidden !important;
    width: 55% !important;
  }

  .provider__container > h1:first-child {
    font-size: 35px !important;
    text-align: start;
  }

  .provider__container > h5 {
    display: block;
    text-align: start;
    font-size: 18px !important;
  }

  .provider__container {
    width: 65% !important;
    display: block !important;
    float: left;
    margin: 0px;
  }

  .provider__containers {
    margin-left: 40px !important;
  }

  .ms-providers-banner {
    height: 85vh;
  }
}
