/* Container for the entire section */
.home2-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white; /* Bottom section is white */
}



/* Gradient background section */
.gradient-section {
    width: 100%;
    min-height: 105vh; /* Ensure the section takes at least 95% of the viewport height */
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
    padding: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* Make it a positioned element */
    overflow: hidden; /* Prevents overflow */
}

/* Content section styling */
.content-section {
    text-align: center;
    width: 65%; /* 65% width for the content section */
    color: white;
}

/* Heading styling */
.heading {
    font-size: 4.1rem;
    margin-bottom: 10%;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 4rem;
    margin-left: 2%;
    width: 100%;
}

/* Button container styling */
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: -75px;
    margin-left: -1%;
    padding-top: 3%;
    border-radius: 10px;
}

/* Individual button styling */
.btn-demo {
    padding: 10px 20px;
    font-size: 1em;
    color: #018C92;
    background-color: #f8f9f9; /* Primary color */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.btn-download {
    padding: 10px 20px;
    font-size: 1em;
    color: #fdfdfd;
    background: transparent;
    border: 1px solid #fdfdfd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-demo:hover {
    background-color: black; /* Change color on hover */
    color: white;
}
.btn-download:hover {
    background-color: black; /* Change color on hover */
    color: white;
}

/* Description text styling */
.description {
    font-size: 1.2em;
    margin-bottom: 50px;
    line-height: 1.5;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

/* Icon row styling */
.icon-row {
    display: flex;
    margin-bottom: 30px;
    margin-left: 16rem;
    transform: translateY(-80%);
    align-items: center;
    gap: 10%;
}

.icon-item {
    text-align: center;
    color: #000;
}

.icon-text {
    font-size: 1.2em;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    transition: transform 0.3s ease; /* Smooth transition effect */
}

.icon-text:hover {
    transform: scale(1.1); /* Scale effect on hover */
}

/* Image section styling */
.image-section {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 30px; /* Decreased space between icons and half-bg-image */
}

/* Half background image */
.half-bg-image {
    width: 45%; /* 40% width of the page */
    object-fit: cover;
    transform: translateY(-55%); /* Adjusted to decrease space between halfBgImage and images below */
    position: relative;
    transition: transform 0.6s ease-in-out, opacity 0.10s ease-in-out; /* Smooth transition effect */
    margin-left: 0%;
}

.half-bg-image:hover {
    transform: translateY(-43%) scale(1.05); /* Slight scale effect on hover */
}

/* Image container section */
.image-container {
    display: flex;
    justify-content: center;
    background-color: white; /* Set background color to white */
    padding: 0 5%; /* Reduce padding to decrease overall space */
    margin-top: -30px; /* Negative margin to bring the images closer to the half-bg-image */
    margin-top: 30%;
}

/* Image styling */
.image {
    height: auto;
    margin: 0 40px; /* Add a small margin between the images */
    transition: transform 0.7s ease-in-out, opacity 0.10s ease-in-out; /* Smooth transition effect */
}

.image:hover {
    transform: scale(1.1); /* Slight scale effect on hover */
    opacity: 0.8; /* Slight fade effect on hover */
}

.image1 {
    width: 20%; /* Custom width for the first image */
}

.image2 {
    width: 40%; /* Custom width for the second image */
}

.image3 {
    width: 20%; /* Custom width for the third image */
}
@media (max-width: 1300px) and (max-height: 475px) and  (max-height: 650px)  {
    .content-section {
        width: 80%; /* Increase width on smaller screens */
       margin-top: 1%;
    }
    .heading {
        font-size: 4em; /* Decrease heading size for tablets */
        margin-top: 4%;
    }
    .icon-row {
        gap: 13%; /* Further reduce gap for smaller screens */
        transform: translateY(-60%);
        margin-left: 28%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    
       
    
    .half-bg-image {
        width: 60%; /* Slightly increase the image size */
        transform: translateY(-45%);
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    .gradient-section {
        height: 90vh;

    }
    
}

/* Responsive design */
@media (max-width: 1100px) {
    .content-section {
        width: 80%; /* Increase width on smaller screens */
       margin-top: 1%;
    }
    .heading {
        font-size: 4em; /* Decrease heading size for tablets */
        margin-top: 10%;
    }
    .icon-row {
        gap: 16%; /* Further reduce gap for smaller screens */
        transform: translateY(-90%);
        margin-left: 25%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    
    .half-bg-image {
        width: 70%; /* Slightly increase the image size */
        transform: translateY(-35%);
    }

    
    .gradient-section {
        height: 90vh;

    }
    
}
@media (max-width: 1020px) {
    .content-section {
        width: 80%; /* Increase width on smaller screens */
       margin-top: 1%;
    }
    .heading {
        font-size: 3em; /* Decrease heading size for tablets */
        margin-top: 10%;
    }
    .icon-row {
        gap: 16%; /* Further reduce gap for smaller screens */
        transform: translateY(-60%);
        margin-left: 20%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    
    .half-bg-image {
        width: 70%; /* Slightly increase the image size */
        transform: translateY(-45%);
    }

    
    .gradient-section {
        height: 90vh;

    }
    
}
@media (max-width: 900px) {
    .content-section {
        width: 85%; /* Increase width on smaller screens */
        margin-top: 5%;
    }
    .heading {
        font-size: 3.3em; /* Smaller font for the heading */
    }

    .icon-row {
        gap: 10%; /* Further reduce gap for smaller screens */
        transform: translateY(-60%);
        margin-left: 25%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    
    

    .image-section {
        margin-top: 20px; /* Adjust space between icons and image on smaller screens */
    }

    .half-bg-image {
        width: 70%; /* Increase image size on smaller screens */
        transform: translateY(-50%); /* Adjust for smaller screens */
    }

    .image-container {
        flex-direction: column;
        align-items: center;
        margin-top: -20px; /* Adjusted to decrease gap on smaller screens */
    }

    .image {
        margin: 10px 0; /* Stack images vertically on smaller screens */
        width: 60%; /* Adjust width for smaller screens */
    }
    .description{
        width:100%;
       
    }
    .gradient-section {
        height: auto !important; 
        min-height: 70vh !important; /* Allow the content to stretch fully */

    }
    .button-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        padding-top: 7%;
      }
}
@media (max-width: 740px) {
    .content-section {
        width: 90%; /* Increase width on smaller screens */
        margin-top: 2%;
    }
    .heading {
        font-size: 3em; /* Smaller font for the heading */
    }

   
    .icon-row {
        gap: -10%; /* Further reduce gap for smaller screens */
        transform: translateY(-40%);
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    

    .image-section {
        margin-top: 20px; /* Adjust space between icons and image on smaller screens */
    }

    .half-bg-image {
        width: 70%; /* Increase image size on smaller screens */
        transform: translateY(-40%); /* Adjust for smaller screens */
    }

    .image-container {
        flex-direction: column;
        align-items: center;
        margin-top: -20px; /* Adjusted to decrease gap on smaller screens */
    }

    .image {
        margin: 10px 0; /* Stack images vertically on smaller screens */
        width: 60%; /* Adjust width for smaller screens */
    }
    .description{
        width:100%;
    }
    .gradient-section {
        height: 85vh;

    }
}
@media (max-width: 680px) {
    .content-section {
        width: 90%; /* Increase width on smaller screens */
        margin-top: 5%;
    }
    .heading {
        font-size: 3em; /* Smaller font for the heading */
    }

   
    .icon-row {
        gap: 15%; /* Further reduce gap for smaller screens */
        transform: translateY(-40%);
        margin-left: 15%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }

    .image-section {
        margin-top: 20px; /* Adjust space between icons and image on smaller screens */
    }

    .half-bg-image {
        width: 70%; /* Increase image size on smaller screens */
        transform: translateY(-50%); /* Adjust for smaller screens */
    }

    .image-container {
        flex-direction: column;
        align-items: center;
        margin-top: -20px; /* Adjusted to decrease gap on smaller screens */
    }

    .image {
        margin: 10px 0; /* Stack images vertically on smaller screens */
        width: 60%; /* Adjust width for smaller screens */
    }
    .description{
        width:100%;
    }
    .gradient-section {
        height: 90vh;

    }
}

@media only screen and (max-width: 480px) {
    .content-section {
        width: 85%;
        margin-top: 7%;
    }
    .heading {
        font-size: 2rem; /* Even smaller font for mobile */
    }

    .half-bg-image {
        width: 100%; /* Make the image bigger for smaller screens */
        transform: translateY(-60%);

    }

    .image {
        width: 80%; /* Images take more width on mobile */
    }
    .gradient-section {
        height: 70vh;

    }
    .icon-row {
        gap: 12%; /* Further reduce gap for smaller screens */
        transform: translateY(-40%);
        margin-left: 7%;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    .description{
        margin-top: 2%;
    }
}

@media only screen and (max-width: 400px) {
    .content-section {
        width: 95%; /* Increase width on smaller screens */
        margin-top: 1%;
    }
    .heading {
        font-size: 1.8em; 
        margin-top: 20%;/* Even smaller font for mobile */
    }

    .half-bg-image {
        width: 100%; /* Make the image bigger for smaller screens */
        transform: translateY(-51%);

    }

    .image {
        width: 80%; /* Images take more width on mobile */
    }
    .gradient-section {
        height: 80vh;

    }
    .icon-row {
        gap: 10%; /* Further reduce gap for smaller screens */
        transform: translateY(-40%);
        margin-left: 4%;

    }
    .description{
        text-align: center;
  font-size: 1.3rem;
  margin-bottom: 50px;
  color: #000;
  align-content: center;
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    .description{
        margin-top: 2%;
    }
    
}
@media only screen and (max-width: 300px) {
    .content-section {
        width: 95%; /* Increase width on smaller screens */
        margin-top: 5%;
    }
    .heading {
        font-size: 1.8em; /* Even smaller font for mobile */
    }

    .half-bg-image {
        width: 100%; /* Make the image bigger for smaller screens */
        transform: translateY(-38%);

    }

    .image {
        width: 80%; /* Images take more width on mobile */
    }
    .gradient-section {
        height:70vh;

    }
    .icon-row {
        gap: 2%; /* Further reduce gap for smaller screens */
        transform: translateY(-40%);
    }

    .icon-text {
        font-size: 0.9em; /* Further adjust icon text size for smaller screens */
    }
    .description{
        margin-top: 2%;
    }
    
}
/* 
@media (max-height: 710px) {
    .content-section {
        width: 95%; 
        margin-top: 0%;
    }
    .heading {
        font-size: 4em;
    width: 70%;
    margin-left: 16%;
    }

    .half-bg-image {
        width:45%; 
        transform: translateY(-55%);

    }

    .image {
        width: 10%; 
    }
    .gradient-section {
        height: 110vh;

    }
    .icon-row {
        gap: 10%;
    transform: translateY(-50%);
    margin-left: 35%;
  
    }

    .icon-text {
        font-size: 0.9em; 
    }
    .description{
        margin-top: 2%;
        font-size: medium;
        width: 70%;
    }
}
@media screen (max-height: 640px) {
    .heading{
        font-size:54px;
    }
}
@media (max-height: 650px)  {
    .content-section {
        width: 95%;
        margin-top: 0%;
    }
    .heading {
        font-size: 38px;
    width: 80%;
    margin-left: 16%;
    }

    .half-bg-image {
        width:45%; 
        transform: translateY(-50%);

    }

    .image {
        width: 10%;
    }
    .gradient-section {
        height: 130vh;

    }
    .icon-row {
        gap: 10%;
    transform: translateY(-50%);
    margin-left: 35%;
  
    }

    .icon-text {
        font-size: 0.9em; 
    }
    .description{
        margin-top: 2%;
        font-size: medium;
        width: 70%;
    }
} 
@media (max-height: 557px)  {
    .content-section {
        width: 95%; 
        margin-top: 0%;
    }
    .heading {
        font-size: 4rem;
       
    }

    .half-bg-image {
        width: 45%;
    transform: translateY(-50%);
    }

    .image {
        width: 10%; 
    }
    .gradient-section {
        height: 140vh;

    }
    .icon-row {
        gap: 10%;
        transform: translateY(-50%);
    }

    .icon-text {
        font-size: 0.9em; 
    }
    .description{
        margin-top: 2%;
        font-size: medium;
        width: 70%;
    }
}
@media (max-height: 475px) {
    .content-section {
        width: 95%; 
        margin-top: 0%;
    }
    .heading {
        font-size: 3.5em;
    width: 70%;
    margin-left: 16%; 
    }

    .half-bg-image {
        width: 40%; 
        transform: translateY(-50%);

    }

    .image {
        width: 10%;
    }
    .gradient-section {
        height: 165vh;

    }
    .icon-row {
        gap: 10%;
        transform: translateY(-50%);
        margin-left: 36%;
      }
    

    .icon-text {
        font-size: 0.9em; 
    }
    .description{
        margin-top: 2%;
        font-size: medium;
        width: 70%;
    }
}
@media (max-height: 380px)  {
    .content-section {
        width: 95%; 
        margin-top: 0%;
    }
    .heading {
        font-size: 4em;
    margin-left: 16%;
    }

    .half-bg-image {
        width: 45%;
        transform: translateY(-50%);

    }

    .image {
        width: 10%; 
    }
    .gradient-section {
        height: 215vh;

    }
    .icon-row {
        gap: 10%; 
        transform: translateY(-50%);
    }

    .icon-text {
        font-size: 0.9em; 
    }
    .description{
        margin-top: 2%;
        font-size: medium;
        width: 70%;
    }
} */