/* Container for the footer */
.footer-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Light grey background */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  height: 13rem;
}

/* Main content layout: logo, text, and social media */
.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px;
  max-width: 1200px;
}

/* Logo styling */
.footer-logo img {
  height: 70px; /* Adjust logo size */
}

/* Center text styling */
.footer-center-text p {
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  width: 25rem;
}

/* Social media icons styling */
.footer-social a {
  margin: 0 10px;
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #007bff; /* Blue color on hover */
}

/* Privacy and Terms links */
.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links a {
  font-size: 14px;
  color: #fffefe;
  text-decoration: none;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #007bff;
}

/* Copyright section */
.footer-copyright {
  width: 100%;
  text-align: center;
  margin-top: 1px;
}

.footer-copyright hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin-bottom: 10px;
}

.footer-copyright p {
  font-size: 12px;
  color: #fffdfd;
  margin: 0;
}

/* Media Queries for responsiveness */

/* Tablets and smaller screens */
@media (max-width: 920px) {
  .footer-content {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
    display: flex;
    flex-direction:column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 23rem;
  }

  .footer-center-text p {
    width: 50%;
    font-size: 13px;
    margin-left: 210px;
  }

  .footer-logo img {
    height: 30px; /* Adjust logo size */
    margin-bottom: 15px;
  }

  .footer-social a {
    font-size: 20px;
    margin: 0 8px;
  }

  .footer-links {
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }
  .footer-copyright p {
    font-size: 10px;
    margin-top: -43px;
  }
}
@media (max-width: 768px) {
  .footer-content {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
    display: flex;
    flex-direction:column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 23rem;
    align-items: center;
  }

  .footer-center-text p {
    width: 80%;
    font-size: 13px;
    margin-left: 80px;
    align-items: center;
  }

  .footer-logo img {
    height: 30px; /* Adjust logo size */
    margin-bottom: 15px;
    align-items: center;
  }

  .footer-social a {
    font-size: 20px;
    margin: 0 8px;
    align-items: center;
  }

  .footer-links {
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .footer-copyright p {
    font-size: 10px;
    margin-top: -43px;
    align-items: center;
  }
}
@media (max-width: 580px) {
  .footer-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Light grey background */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 16.5rem;
    align-items: center;
  }

  .footer-logo img {
    height: 30px; /* Adjust logo size */
    margin-bottom: 10px;
    align-items: center;
  }

  .footer-center-text p {
    font-size: 16px;
    width: 100%;
    align-items: center;
  }

  .footer-social a {
    font-size: 18px;
    margin: 0 5px;
    align-items: center;
  }

  .footer-links a {
    font-size: 12px;
    align-items: center;
  }

  .footer-copyright p {
    font-size: 10px;
    margin-top: -26px;
    align-items: center;
  }
  .footer-center-text p {
    width: 66%;
    font-size: 13px;
    margin-left: 22%;
    align-items: center;
  }

}

/* Mobile phones */
@media (max-width: 480px) {
  .footer-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Light grey background */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 16.5rem;
    align-items: center;
  }

  .footer-logo img {
    height: 30px; /* Adjust logo size */
    margin-bottom: 10px;
    align-items: center;
  }

  .footer-center-text p {
    font-size: 16px;
    width: 100%;
    align-items: center;
  }

  .footer-social a {
    font-size: 18px;
    margin: 0 5px;
    align-items: center;
  }

  .footer-links a {
    font-size: 12px;
    align-items: center;
  }

  .footer-copyright p {
    font-size: 10px;
    margin-top: -26px;
    align-items: center;
  }
  .footer-center-text p {
    width: 74%;
    font-size: 13px;
    margin-left: 60px;
    align-items: center;
  }

}
@media (max-width: 325px) {
  .footer-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Light grey background */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 16.5rem;
    align-items: center;
  }

  .footer-logo img {
    height: 30px;
    margin-bottom: 14px;
    align-items: center;
    margin-top: -26px;
  }

  .footer-center-text p {
    font-size: 16px;
    width: 100%;
    align-items: center;
  }

  .footer-social a {
    font-size: 18px;
    margin: 0 5px;
    align-items: center;
    margin-top: -70px;
  }

  .footer-links {
    font-size: 12px;
    align-items: center;
    margin-top: -30px;
  }

  .footer-copyright p {
    font-size: 10px;
    margin-top: -40px;
    align-items: center;
  }
  .footer-center-text p {
    width: 87%;
    font-size: 10px;
    margin-left: 21px;
    align-items: center;
    margin-top: -55px;
  }
.footer-social{
  margin-top: -50px;
}
}
