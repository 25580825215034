@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Sen&display=swap');

:root{
    
    --sen : 'Sen', sans-serif;
    --roboto:'Roboto', sans-serif;
    --white:#ffffff;
    --black:#000000;
    --violet:#7C83FD;
    --blue:#96BAFF;
    --green:#93FFD8;
    --gGreen:#CFFFDC;
    --background:rgba(239, 251, 252, 0.5);
    --ms-primary-color:#005c74;
    --ms-second-color:#01cab8;
    --ms-third-color:#858585;
    --ms-dark-color:#212529;
    --ms-second-font:'Poppins';
}

*{
    margin: 0px ;
    padding: 0px;
    /* border: 1px solid red; */
}

.flex__box{
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none !important;
    transition: 0.3s all linear;
}
a:hover {
    color: var(--ms-second-color);
}
body {
    font-family: var(--ms-second-font);
    font-size: 17px;
    line-height: 1.55em;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.35em;
    font-family: 'Montserrat', sans-serif;
}
h1 {
    font-size: 56px;
    color: var(--ms-primary-color);
}
h2 {
    font-size: 42px ;
}
h3 {
    font-size: 34px;
}
h4 {
    font-size: 26px;
}
h5 {
    font-size: 22px;
}
h6 {
    font-size: 19px;
}
.ms-font-poppins {
    font-family: var(--ms-second-font);
}
.ms-primary-color {
    color: var(--ms-primary-color);
}
.ms-second-color {
    color: var(--ms-second-color);
}
.ms-third-color {
    color: var(--ms-third-color);
}
.ms-dark-color {
    color: var(--ms-dark-color);
}
.ms-font-weight-600 {
    font-weight: 600;
}
.ms-primary-btn, .ms-secondary-btn {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    padding: 16px 32px;
    border-radius: 30px;
    z-index: 1;
    transition: all 500ms ease;
}
.ms-primary-btn:before, .ms-secondary-btn:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 30px;
    left: 0px;
    top: 0px;
    z-index: -1;
    transform: scale(0,0);
    transition: all 500ms ease;
}
.ms-primary-btn {
    color: var(--ms-primary-color);
    background: #fff;
    border:2px solid #fff;
}
.ms-primary-btn:before {
    background: var(--ms-primary-color);
}
.ms-primary-btn:hover {
    background: var(--ms-primary-color);
    color: #fff;
    border:2px solid var(--ms-primary-color);
}
.ms-primary-btn:hover:before,
.ms-secondary-btn:hover:before {
    transform: scale(1,1);
}
.ms-secondary-btn {
    background: var(--ms-primary-color);
    border:2px solid var(--ms-primary-color);
    color: #fff !important;
}
.ms-secondary-btn:before {
    background: var(--ms-second-color);
}
.ms-secondary-btn:hover {
    background: var(--ms-second-color);
    border: 2px solid var(--ms-second-color);
    color:#fff;
}
.ms-border-rounded {
    border-radius: 30px;
}

@media (max-width:767px) {
    /*==================== 01. General CSS ====================*/
    h1 {
        font-size:50px ;
    }
    h2 {
        font-size: 38px ;
    }
    h3 {
        font-size: 31px;
    }
    h4 {
        font-size: 23px;
    }
    h5 {
        font-size: 18px;
    }
}

@media (max-width:550px) {
    /*==================== 01. General CSS ====================*/
    h1 {
        font-size:42px;
    }
    h2 {
        font-size: 34px;
    }
    h3 {
        font-size: 29px;
    }
}
.patient{
    display: flex;
    flex-direction: column;
    margin-top: 157px;

}
.whatsapp-block{
    height: 0;
    width: 49px;
    position: fixed;
    right: 24px;
    top: calc(50% + 160px);
    z-index: 1000;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
.whatsapp-block a{
    display: block;
    border-radius: 22px 22px 0 0;
    background: #005c74;
}
.whatsapp-block a img{
transform: rotate(-90deg);
-webkit-transform: rotate(90deg);
margin: 5px;
width: 40px;
border-radius: 50%;
}
/* .providersss{
    display: flex;
    align-items: center;
    justify-content: center;

} */