/* Container for the counters section */
.counters-section {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping */
    justify-content: space-between;
    padding: 1px 0;
    width: 100%;
    max-width: 1300px;
    margin: -20px auto 0 auto;
    gap: 20px; /* Space between boxes */
}


/* Individual counter boxes */
.counter-box {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
    color: #fff;
    width: 23%; /* 4 boxes per row on desktop */
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px; /* Ensure margin between rows */
}

/* Counter box on hover */
.counter-box:hover {
    transform: scale(1.05);
}

/* Counter styling */
.counter {
    font-size: 4.4vw;
    margin-bottom: 10px;
    font-weight: bold;
}

/* Text below the counter */
.counter-text {
    font-size: 1.2em;
    margin-top: 10px;
}

/* For tablet screens and below: Show 1 box per row */
@media (max-width: 768px) {
    .counters-section {
        justify-content: center; /* Center the box */
        gap: 20px; /* Space between rows */
    }
    
    .counter-box {
        width: 100%; /* Full width (1 box per row) */
        max-width: 600px; /* Optional: Limit the width for readability */
    }
}

/* For mobile screens: Keep 1 box per row */
/* For tablet screens: 1 box per row with 80% width and 10% margin from sides */
@media (max-width: 768px) {
    .counters-section {
        justify-content: center;
        gap: 20px; /* Space between boxes */
    }

    .counter-box {
        width: 80%; /* Box occupies 80% width */
        margin: 0 auto 20px auto; /* Center the box and add bottom margin */
        font-size: 1.6vw;
    }
    .counter-text {
        font-size: 1.2vw;
        margin-top: 10px;
    }
}

/* For mobile screens: Same as tablet with 80% width and centered */
@media (max-width: 480px) {
    .counter-box {
        width: 80%; /* Box occupies 80% width */
        margin: 0 auto 20px auto; /* Center the box and add bottom margin */
        font-size: 1.7vw;
    }
    .counter-text {
        font-size: 50rem;
        margin-top: 8px;
    }
}

