/* /==================== 03. About Page CSS ====================/
/----- 03.a. Banner Section -----/ */
.ms-about-banner {
    padding:180px 10px 110px;
    background:  #018C92;
    background-repeat: no-repeat;
    background-position:  center;
    background-size: cover;
}
.ms-page-header-title {
    color: #fff;
    font-weight: 600;
}
.ms-team-section {
    padding:100px 0px !important;
}
.ms-team-box img {
    border-radius: 50%;
    width: 200px;
}

.team__section{
   margin-top: 1px !important;
}

.ms-team-box h6 {
    font-size: 17px;
}
.ms-team-box p {
    font-size: 16px;
    color: var(--ms-third-color);
}
@media (min-width:992px) {
    .ms-team-section .col-md-4:nth-child(2) {
        border-left: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
    }
}
.ms-contact-section {
    background: #f6f7f8;
}

.margin__second{
    margin: 50px 0px;
}

.margin__first{
    margin-top: 30px;
}

.ms-contact-section .ms-right-content {
    padding: 60px 150px 60px 60px;
    margin-top:30px;
    background: url('../Images/background-img.png');
    background-position: bottom center;
    background-repeat: no-repeat;
}
.ms-contact-section form .form-control:focus {
    box-shadow: none;
}
.ms-contact-section form input,
.ms-contact-section form textarea {
    padding:15px 20px;
    border-radius: 30px;
}
.ms-contact-section .ms-social-links a {
    width: 50px;
    height: 50px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 3px;
    border: 1px solid var(--ms-primary-color);
}
.ms-contact-section .ms-social-links a .ms-icon {
    font-size: 20px;
}
.ms-contact-section .ms-social-links a:hover {
    background: var(--ms-second-color);
    border: 1px solid var(--ms-second-color);
}
.ms-contact-section iframe {
    width: 100%;
    height: 776px;
    margin-bottom: -7px;
}
@media screen and (min-width: 780px) and (max-width: 1200px) {
    .content__logo > img {
      margin: 30px 0;
      width: 60px !important;
    }
  }


@media (max-width:1570px) {
    /* /==================== 03. About Page CSS ====================/ */
    /* /----- 03.c. Contact Section -----/ */
    .ms-contact-section .ms-right-content {
        padding: 60px;
    }
    
}
@media (min-width: 1210px) {
    .content__logo > img {
      margin: 30px 0;
      width: 70px !important;
    }
  }

@media (max-width: 550px) {
    /* /==================== 03. About Page CSS ====================/
    /----- 03.c. Contact Section -----/ */
    .ms-contact-section.ms-right-content {
        padding: 60px 20px;
    }

    .ms-contact-section iframe {
        height: 500px;
    }
}
