/* Container for the "Before Footer" section */
.before-footer-container {
    width: 100%; /* Full width */
    background-color: hwb(180 72% 24%); /* Background color */
    display: flex; /* Flexbox for centering */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    padding: 10px; /* Add padding for better appearance */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    overflow: hidden; /* Prevent content from overflowing */
  }
  
  
  /* Content wrapper */
  .before-footer-content {
    display: flex; /* Flexbox for horizontal alignment */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center horizontally */
    flex-direction: column; /* Stack content vertically on small screens */
    gap: 10px; /* Add space between the text and button */
    max-width: 1200px; /* Constrain the width of the content */
    width: 100%; /* Make content fill the container */
    text-align: center; /* Center text and button */
    margin: 0 auto; /* Center the content horizontally */
  }
  
  /* Text styling */
  .before-footer-text {
    color: #1D1D1D; /* Text color */
    font-size: 1.5em; /* Default font size */
    margin-bottom: 10px; /* Margin below text */
  }
  
  /* Button styling */
  .before-footer-button {
    background-color: #005C74; /* Button background color */
    color: white; /* Button text color */
    border: none; /* No border */
    padding: 10px 20px; /* Padding for the button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1em; /* Font size */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    height: auto; /* Button height automatically adjusts */
    white-space: nowrap; /* Prevent the button text from wrapping */
  }
  
  /* Button hover effect */
  .before-footer-button:hover {
    background-color: #050505; /* Button hover background color */
    color: white;
  }
  
  /* Media query for smaller devices (max-width: 768px) */
  @media (max-width: 900px) {
    .before-footer-text {
        font-size: 1.3em; /* Adjust font size for smaller screens */
        margin-bottom: 8px; /* Adjust margin below text */
      }
    
      
      .before-footer-button {
          font-size: 0.8em; /* Adjust button font size */
          padding: 8px 12px; /* Adjust padding */
          margin-bottom: 15px; /* Negative margin to adjust button position */
        }
  }

  @media (max-width: 768px) {
    .before-footer-text {
      font-size: 1.3em; /* Adjust font size for smaller screens */
      margin-bottom: 8px; /* Adjust margin below text */
    }
  
    
    .before-footer-button {
        font-size: 0.8em; /* Adjust button font size */
        padding: 8px 12px; /* Adjust padding */
        margin-bottom: 15px; /* Negative margin to adjust button position */
      }
  }
  
  /* Media query for very small devices (max-width: 576px) */
  @media (max-width: 576px) {
    .before-footer-content {
      flex-direction: column; /* Stack the text and button vertically */
      padding: 10px; /* Adjust padding */
      margin: 0 5%; /* Add horizontal margin to prevent overflow */
    }
  
    .before-footer-text {
      font-size: 1.1em; /* Adjust font size */
      margin-bottom: 8px; /* Adjust margin below text */
    }
  
    .before-footer-button {
      font-size: 0.8em; /* Adjust button font size */
      padding: 8px 12px; /* Adjust padding */
      margin-bottom: 15px; /* Negative margin to adjust button position */
    }
  }
  