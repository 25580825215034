.pp__first{
    width: 80%;
    margin: auto;
    padding: 20vh 0px !important;
}

.pp__first>h1{
      text-align: center;
      margin-bottom: 50px;
      font-weight: bold;
      text-decoration: underline;
}

.schdule{
    margin-top: 50px;
}


.pp__listGap>li{
    margin: 10px 0px !important;
    padding-left: 10px;
}

.pp__PS>li{
    padding-left: 14px !important;
    
}

.pp__PS>li>p>span{
    font-weight: bold;
    padding-right: 10px;
}

.pp__PS>li>p:first-child{
    font-weight: 500;
    margin-top: 10px;
    padding: none !important;
    text-decoration: underline;
}


.pp__cas>li>p>span{
    padding-right: 10px;
    font-weight: 500;
}

.pp__cas>li>p{
    padding-left: 10px;
}

.schdule>h3{
    text-align: center;
    text-decoration: underline;
    margin-top: 80px !important;
}

.schdule>h4{
     text-decoration: underline;
    text-align: center;
    margin-bottom: 30px;

}

.tt__firstpara>h4{
    text-align: center;
    text-decoration: underline;
    font-size: 22px !important;
    margin-bottom: 40px;
}

.tt__secondpara>h4{
    text-align: center;
    text-decoration: underline;
    font-size: 22px !important;
    margin: 30px 0px;
}

.tt__secondpara>h5{
    text-align: center;
    font-size: 20px !important;
}

.tt__secondpara>ol>li{
    margin-top: 30px;
}

.list__gap>li{
    margin: 8px 0px !important;
}

.tt__last>h1{
    text-align: center;
    text-decoration: underline;
    margin: 30px 0px;
    font-size: 30px;
}