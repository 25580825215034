/* Container for the counters section */
.counters-section {
    display: flex;
    justify-content: space-between;
    background-color: white; /* Light background */
    padding: 20px 0;
    width: 100%;
    max-width: 1300px; /* Adjust max-width as needed */
    margin: 0 auto;
}


/* Individual counter boxes */
.counter-box {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Box color */
    color: #fff; /* Text color */
    width: 23%; /* Adjust width to fit four boxes in one line */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Counter styling */
.counter5 {
    font-size: 2em; /* Adjust font size as needed */
    margin-bottom: 10px;
}

/* Text below the counter */
.counter-text {
    font-size: 1em;
    margin-top: 10px;
}
@media (min-width: 1300px) {
    .counter-box {
        flex: 0 1 23%; /* Four boxes in one row for larger screens */
    }
}
@media (min-width: 900px) {
    .counter-box {
        flex: 0 1 23%; /* Four boxes in one row for larger screens */
        font-size: larger;
    }
}

/* Media query for screens 500px or narrower */
@media (max-width: 500px) {
    .counter-box {
        flex: 0 1 40%; /* One box per row on smaller screens */
        font-size: larger;
    }
}