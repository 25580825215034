
.ms-cureways-section{
    padding: 80px 0;
    background:  #018C92;
    margin-top: 10%;
}
.ms-cureways-section .ms-icon-box {
    padding:50px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 30px #eaf2f1;
    transition: all 500ms ease;
    text-align: center;
    min-width: 300px;
    min-height: 280px;
    background:url('../Images/icon-back-box.png')   rgb(148 227 231);
    background-size:cover;
    background-repeat: no-repeat;
    background-position:-15%;
}
.ms-apart-section .ms-icon-box img {
    width: 80px;
}
.ms-apart-section .ms-icon-box h5 {
    font-size: 18px;
    line-height: 1.5em;
}
.ms-cureways-section .ms-icon-box h4{
    color: #006076;
}
.ms-cureways-section .ms-icon-box img{
    width: 80px;
}
/* #006076 */

/* .ms-apart-section .ms-icon-box {
    min-height: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
/*----- 02.c. Connect Section -----*/
.ms-content-section {
    background:  rgba(255,255,255,0.6);
    background-blend-mode: overlay;
    padding: 0px 0;
    overflow: hidden;

}

.content__logo>img{
    width: 55px !important;
    margin: 30px 0px;
}


.margin__first{
    margin-top: 30px !important;
}

.margin__second{
    margin: 60px 0px !important;
}

.fade__img{
    height: 100%;
    background-blend-mode:multiply;
}

.ms-content-section .ms-check-icon {
    color: var(--ms-second-color);
    position: relative;
    top: 4px;
    font-size: 20px;
}
.ms-content-section h6 {
    color: var(--ms-third-color);
    line-height: 1.65em;
    font-size: 18px;
    font-family: var(--ms-second-font);
}

  
  @media (max-width: 992px) {
    .scrollable-content,
    .fixed-image-container {
      height: auto;
    }
  
    .content-section {
      min-height: auto;
      margin-bottom: 2rem;
    }
  
    .fixed-image-container {
      position: static;
      margin-bottom: 2rem;
    }
  
    .fixed-image {
      position: static;
      opacity: 1;
      margin-bottom: 2rem;
    }
  }




@media screen and (min-width: 780px) and (max-width: 1200px){
    


    .content__logo>img{
        width: 60px !important;
        margin: 30px 0px;
    }
    
}



@keyframes fade-in-up {
    0% { opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
  
  .video {
    position: fixed;
    bottom: 50px; /* Adjust as needed */
    right: 5%; /* Move to the center horizontally */
    transform: translateX(5%); /* Center the video */
    z-index: 9999; /* Ensure it's above other content */
  
  }
  


  
  
   

 