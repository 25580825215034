/* Container for the entire page */
.testimonials-page-container {
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    background-color: #ffffff;
}

/* Container for the heading and description */
.header-container {
    text-align: start;
    margin-bottom: 40px;
}

/* Heading and description styling */
.testimonials-heading {
    font-size: 2em;
    color: #005C74;
    margin-bottom: 10px;
}


.testimonials-description {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
    
}

/* Main container for the testimonials content */
.testimonials-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;
}

/* Left side content */
.left-content {
    width: 50%; /* Adjusted to cover 50% of the width */
}

/* Testimonial box styling (Image and Doctor Info) */
/* Video Container */
.testimonial-video-container {
    width: 100%;
    height: 21.8rem; /* Desktop size */
    overflow: hidden;
    position: relative;
}

/* Full-width Video */
.testimonial-video {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: block;
}

/* Mobile View */
@media screen and (max-width: 768px) {
    .testimonial-video-container {
        height: 16rem; /* Adjusted height for mobile */
    }
}

@media screen and (max-width: 480px) {
    .testimonial-video-container {
        height: 9.5rem; /* Smaller height for smaller screens */
    }
}

/* Doctor's image */
.doctor-image {
    width: 200px; /* Adjust size as needed */
    height: 200px; /* Ensure it remains a circle */
    border-radius: 50%; /* Make the image round */
    object-fit: cover; /* Ensure the image fits within the circle */
    margin-right: 0; /* Adjust spacing */
}

/* Doctor's information */
.doctor-info {
    width: 55%;
}

.doctor-name {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.2em;
}

.doctor-post {
    font-style: italic;
    margin-bottom: 10px;
}

.testimonial-text {
    font-size: 1em;
    margin-left: 2%;
}

/* Right side content (Counter Box) */
.right-content {
    width: 40%; /* Adjusted for a balanced layout */
}

/* Counter box styling */
.New-box {
    /* background: linear-gradient(45deg, #005C74, #018C92, #01A09E); */
    background-color: #005C74;
    padding: 20px;
    padding-right: 5%;
    border-radius: 20px;
    text-align: center;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width :120%;
    height:22.2rem;
}

.New-box:hover {
    transform: scale(1.05);
}

/* .counter {
    font-size: 3em;
    margin-bottom: 15px; */
/* } */

.New-box-description {
    display: flex;   
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    margin-top: 10%;
}


/* Responsive Design */
@media (max-width: 768px) {
    .testimonials-container {
        flex-direction: column;
        align-items: center;
    }

    .left-content, .right-content {
        width: 100%; /* Full width on smaller screens */
    }

    .testimonial-box {
        flex-direction: column; /* Stack image and text on small screens */
        text-align: center;
    }

    .doctor-image {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .right-content {
    margin-top: 4rem;
}

/* Container for the counter boxes */
.counters-section {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Space between boxes */
}

/* Individual counter boxes */
.counter-box {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); /* Box color */
    color: #fff; /* Text color */
    width: 23%; /* Adjust width to fit four boxes in one line */
    padding: 20px;
    border-radius: 15px; /* Rounded corners */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for a 3D effect */
    transition: transform 0.3s ease-in-out; /* Smooth animation on hover */
}

/* Counter box on hover */
.counter-box:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Counter styling */
.counter2 {
    font-size: 2.5em; /* Adjust font size as needed */
    margin-bottom: 10px;
    font-weight: bold;
}

/* Text below the counter */
.counter-text {
    font-size: 1.2em;
    margin-top: 10px;
}
}
/* Responsive Design */
/* Adjustments for responsive screens */
@media (max-width: 1200px) {
    .testimonial-box {
        padding: 15px; /* Decrease padding */
        height: auto; /* Adjust height */
    }

    .doctor-image {
        width: 60%; /* Decrease image size */
    }

    .doctor-name {
        font-size: 1em; /* Adjust font size */
    }

    .doctor-post {
        font-size: 0.9em; /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9em; /* Adjust text size */
    }

    .New-box {
        padding: 15px; /* Decrease padding */
        width: 120%; /* Adjust width */
        height: 17.8rem; /* Adjust height */
    }

    .New-box-description {
        font-size: 1.2em; /* Adjust description text size */
    }

    .counter {
        font-size: 2em; /* Adjust counter font size */
    }
}
@media (max-width: 1000px) {
    .testimonial-box {
        padding: 15px; /* Decrease padding */
        height: auto; /* Adjust height */
    }

    .doctor-image {
        width: 60%; /* Decrease image size */
    }

    .doctor-name {
        font-size: 1em; /* Adjust font size */
    }

    .doctor-post {
        font-size: 0.9em; /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9em; /* Adjust text size */
    }

    .New-box {
        padding: 15px; /* Decrease padding */
        width: 120%; /* Adjust width */
        height: 20.5rem; /* Adjust height */
    }

    .New-box-description {
        font-size: 1.2em; /* Adjust description text size */
    }

    .counter {
        font-size: 2em; /* Adjust counter font size */
    }
}


@media (max-width: 800px) {
    .testimonial-box {
        padding: 25px; /* Decrease padding */
        height: 40%; /* Adjust height */
    }

    .doctor-image {
        width: 24%; /* Decrease image size */
        height: auto;
    }

    .doctor-name {
        font-size: 1em; /* Adjust font size */
    }

    .doctor-post {
        font-size: 0.9em; /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9em; /* Adjust text size */
    }

    .New-box {
        padding: 15px; /* Decrease padding */
        width: 100%; /* Adjust width */
        height: 12rem; /* Adjust height */
    }

    .New-box-description {
        font-size: 1.2em; 
        width: 50%;/* Adjust description text size */
        margin-left: 25%;
        margin-top: -0.2%;
    }

    .counter {
        font-size: 2em; /* Adjust counter font size */
    }
}
@media (max-width: 700px) {
    .testimonial-box {
        padding: 25px; /* Decrease padding */
        height: 40%; /* Adjust height */
    }

    .doctor-image {
        width: 30%; /* Decrease image size */
        height: 20%;
    }

    .doctor-name {
        font-size: 1em; /* Adjust font size */
    }

    .doctor-post {
        font-size: 0.9em; /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9em; /* Adjust text size */
    }

    .New-box {
        padding: 15px; /* Decrease padding */
        width: 100%; /* Adjust width */
        height: 13rem; /* Adjust height */
    }

    .New-box-description {
        font-size: 1em; /* Adjust description text size */
    }

    .counter {
        font-size: 2em; /* Adjust counter font size */
    }
}
@media (max-width: 400px) {
    .testimonial-box {
        padding: 15px; /* Decrease padding */
        height: 10%; /* Adjust height */
    }

    .doctor-image {
        width: 50%; /* Decrease image size */
    }

    .doctor-name {
        font-size: 1em; /* Adjust font size */
    }

    .doctor-post {
        font-size: 0.9em; /* Adjust font size */
    }

    .testimonial-text {
        font-size: 0.9em; /* Adjust text size */
    }

    .New-box {
        padding: 15px; /* Decrease padding */
        width: 100%; /* Adjust width */
        height: auto; /* Adjust height */
    }

    .New-box-description {
        font-size: 1em; /* Adjust description text size */
        width: 50%;
    }

    .counter {
        font-size: 1.5em; /* Adjust counter font size */
    }
}
