/* Container for the entire section */
/* .container-custom {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); 
    padding: 4% 0; 
    overflow: hidden;
  }
   */
  /* Container for the service rectangle */
  .container-custom {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E); 
    padding: 4% 0; 
    overflow: hidden;
  }


  .service11-rectangle {
    display: flex;
    flex-direction: column;
    background-color: #f6f8f8; /*#d3d3d3; /* Light grey background color */
    margin: 10px auto; /* Center the rectangle and add margin */
    width: 80%; /* Rectangle width */
   
    box-sizing: border-box;
    border-radius: 15px; /* Rounded corners */
    overflow: hidden; /* Hide any overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .racHead8{
    font-family: initial;
    background-color:hwb(180 72% 24%) ;
    width: 100%;
    padding: 22px 15px 12px 8px;
  }
  .racH8{
    margin-left: 35%;
    color: #1D1D1D;
  }
  /* Container for each service section inside the rectangle */
  .service11-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Each section takes full width of the rectangle */
    margin-bottom: 20px; /* Space between sections */
    padding: 10px; /* Added padding for each service section */
    box-sizing: border-box;
  }
  
  /* Reverse order if necessary */
  .service11-section .order-last {
    order: 2; /* Move to last order within flex */
  }
  
  /* Container for the service image */
  .service11-img-container {
    flex: 0 0 45%; /* Adjust the flex basis to 45% */
    max-width: 60%; /* Ensure max-width is 45% */
    padding: 10px; /* Padding around the image container */
    box-sizing: border-box; /* Include padding in the width */
    margin-left: 40px;
  }
  
  /* Style for the service image */
  .service11-img {
    width: 70%; /* Make the image take full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers its container proportionally */
    border-radius: 10px; /* Add slight rounding to the images */
    margin-left: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation properties for hover */
  }
  
  /* Hover effect for service images */
  .service11-img:hover {
    transform: scale(1.05); /* Slightly scale up the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a soft shadow */
  }
  
  /* Style for the service text */
  .service11-text {
    flex: 0 0 50%; /* Adjust the flex basis to 55% */
    max-width: 50%; /* Ensure max-width is 55% */
    padding: 20px; /* Added padding inside the text container */
    color: #0a0909;
    text-align: left; /* Align text to the left */
    box-sizing: border-box; /* Include padding in the width */
    font-size: larger;
  }
  
  /* Style for the button */
  .btn-light {
    background-color: #005C74;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 20px; /* Added padding for button */
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px; /* Margin above button */
  }
  
  /* Button hover state */
  .btn-light:hover {
    background-color: #000; /* Change background color to black */
    color: #fff; /* Change text color to white */
  }
  
  /* Media query for responsiveness */
  @media (max-width: 1100px) {
    .service8-section {
        flex-direction: column; /* Stack images and text on smaller screens */
        align-items: center; /* Center items */
        margin-bottom: 30px; /* Increase space between stacked sections */
    }
    
    .service8-img-container,
    .service8-text {
        width: 100%; /* Full width on smaller screens */
        max-width: 100%; /* Ensure containers use full width */
        padding: 10px; /* Adjust padding for smaller screens */
    }
    
  .uppHead{
    margin-bottom: 30%;
    font-size: 2vh;
    margin-top: 12%;
    height: 6vh;
  }
  }  
  @media (max-width: 900px) {
    .uppHead{
      margin-bottom: 10%;
      font-size: 2vh;
      margin-top: 10%;
    }
    .service11-img {
      width: 100%; /* Full width for images */
      margin-left: 0; /* Remove margin */
    }
    /* .second-section {
      flex-direction: column-reverse;
      align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box; 
    } */
    /* .second-image{
      margin-left: 40%;
    }
    service11.heading{
      margin-left: -40%;
    } */
}
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .service11-section {
        flex-direction: column; /* Stack images and text on smaller screens */
        align-items: center; /* Center items */
        margin-bottom: 30px; /* Increase space between stacked sections */
    }
    
    .service11-img-container,
    .service11-text {
        width: 100%; /* Full width on smaller screens */
        max-width: 100%; /* Ensure containers use full width */
        padding: 10px; /* Adjust padding for smaller screens */
    }
    
    .uppHead{
      margin-bottom: 20%;
      font-size: 14px;
      margin-top: 25%;
      height: 10vh;
      margin-top: 3%;
    }
  .UpTex{
    margin-top: -30px;
    margin-bottom: 6%;
  }
.second-section {
  flex-direction: column-reverse; /* Reverse order of image and text */
}
.service11-rectangle {
  display: flex;
  flex-direction: column;
  background-color: #f6f8f8;
  margin: 10px auto;
  width: 95%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.service11-img {
  width: 100%;
  margin-left: -6%;
}
  }  
 
  @media (max-width: 576px) {
    .uppHead{
      margin-bottom: 20%;
      font-size: 14px;
      margin-top: 25%;
      height: 10vh;
      margin-top: 3%;
    }
  .UpTex{
    margin-top: -30px;
    margin-bottom: 6%;
  }
  .service11-section {
    margin-bottom: 20px; /* Further reduce spacing for mobile */
  }
  
  .service11-text {
    width: 100%; /* Full width for text */
    padding: 10px; 
    font-size: 4vw;/* Reduce padding */
  }
  .second-section {
    flex-direction: column-reverse; /* Reverse order of image and text */
  }
  .service11-rectangle {
    display: flex;
    flex-direction: column;
    background-color: #f6f8f8;
    margin: 10px auto;
    width: 95%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .service11-img {
    width: 100%;
    margin-left: -13%;
  }
}