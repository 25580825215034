.cureways{
    display: flex;
    flex-direction: column;
    margin-top: 157px;
}
.curecards{
    height: 100%;
    h2{
        text-align: center;
        font-weight: 700;
        color: #fff;
    }
    p{
      
        text-align: center;
        margin-top: 1rem;
        font-size: 17px;
        font-weight: 500;
        color: #32979a;
    }
}
.content-container {
  /* Styles for container */
  display: flex;
  flex-direction: column;
}


.images-container {
    animation: moveLeftToRight 5s linear infinite; /* Change the duration (5s) as needed */
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%); /* Adjust the distance (100%) as needed */
    }
  }

  .doctors-column{
    position: relative;
    max-width: 198px;
    overflow-y: auto;
    height: 100vh;
    margin-top: 148px;
    display: flex; 
    flex-wrap:  wrap; 
    justify-Content: space-evenly; 
    align-Items: flex-start; 
  }

  @media (max-width:550px) {
    .doctors-column {
      max-width: 198px;
      overflow-x: auto;
      margin-top:164px; /* Adjust as needed */
  
    }
            .doctors-column::-webkit-scrollbar{
              width: 0;
            }
            .doctors-column .m-doctors{
              min-width: 100px;
              height: 100px;
              line-height: 100px;
              text-align: center;
              margin-right: 2px;
            }
  
    
  }
  
  