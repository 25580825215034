/* Container for the entire section */
.container-custom {
  background-color: #d3d3d3; /*#f6f8f8; /* Background color */
  padding: 4% 0; /* Adjusted padding */
  overflow: hidden;
}
.outsd{
  background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
  padding: 20px;
  margin-top: -100px;
}
.headLine{
  background: linear-gradient(45deg, #005C74, #018C92, #01A09E);
  padding: 3%;
}
.uppHead {
  background-color: #005C74;
  height: 8.5vh; /* Removed quotes */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8%; /* Removed quotes */
  margin-left: 11%; /* Removed quotes */
  border-radius: 10px;
  width: 78%; /* Removed quotes */
  color: #fff;
  font-family: initial;
}
.UpTex{
  color: #fff;
   margin: 0;
  font-family: initial;
 
}
/* Container for the service rectangle */
.service-rectangle {
  display: flex;
  flex-direction: column;
  background-color: #f6f8f8; /*#d3d3d3; /* Light grey background color */
  margin: 100px auto; /* Center the rectangle and add margin */
  width: 80%; /* Rectangle width */
 
  box-sizing: border-box;
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Hide any overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Container for each service section inside the rectangle */
.service-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Each section takes full width of the rectangle */
  margin-bottom: 20px; /* Space between sections */
  padding: 10px; /* Added padding for each service section */
  box-sizing: border-box;
}
.racHead1{
  font-family: initial;
  background-color:hwb(180 72% 24%) ;
  width: 100%;
  padding: 22px 15px 12px 8px;
}
.racH1{
  margin-left: 24%;
  color: #1D1D1D;
}
/* Reverse order if necessary */
.service-section .order-last {
  order: 2; /* Move to last order within flex */
}

/* Container for the service image */
.service-img-container {
  flex: 0 0 45%; /* Adjust the flex basis to 45% */
  max-width: 45%; /* Ensure max-width is 45% */
  padding: 10px; /* Padding around the image container */
  box-sizing: border-box; /* Include padding in the width */
  margin-left: 40px;
}

/* Style for the service image */
.service-img {
  width: 65%; /* Make the image take full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers its container proportionally */
  border-radius: 10px; /* Add slight rounding to the images */
  margin-left: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation properties for hover */
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Hover effect for service images */
.service-img:hover {
  transform: scale(1.05); /* Slightly scale up the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a soft shadow */
}

/* Style for the service text */
.service-text {
  flex: 0 0 50%; /* Adjust the flex basis to 55% */
  max-width: 50%; /* Ensure max-width is 55% */
  padding: 20px; /* Added padding inside the text container */
  color: #0a0909;
  text-align: left; /* Align text to the left */
  box-sizing: border-box; /* Include padding in the width */
  font-size: larger;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Style for the button */
.btn-light {
  background-color: #005C74;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px; /* Added padding for button */
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px; /* Margin above button */
}

/* Button hover state */
.btn-light:hover {
  background-color: #000; /* Change background color to black */
  color: #fff; /* Change text color to white */
}

/* Container for the counters section */
.counters0-section {
  display: flex;
  justify-content: space-between;
 
  padding: 10px 0;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  gap: 10px;
  flex-wrap: wrap; /* Add flex-wrap for smaller screens */
}

/* Individual counter boxes */
.Counter0-box {
  background-color: #005C74;
  color: #fff;
  flex: 1; /* Allows boxes to adjust based on screen size */
  min-width: 200px; /* Set a minimum width for each box */
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px; /* Add space between rows on small screens */
}

/* Hover effect for counter boxes */
.Counter0-box:hover {
  transform: scale(1.05);
}

/* Counter number styling */
.Counter0 {
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 10px;
}

/* Text below the counter */
.Counter0-text {
  font-size: 1em;
  margin-top: 10px;
}
@media (max-width: 1100px) {
  .service8-section {
      flex-direction: column; /* Stack images and text on smaller screens */
      align-items: center; /* Center items */
      margin-bottom: 30px; /* Increase space between stacked sections */
  }
  
  .service8-img-container,
  .service8-text {
      width: 100%; /* Full width on smaller screens */
      max-width: 100%; /* Ensure containers use full width */
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .Counter11-section {
    gap: 15px; /* Increase the gap for better spacing */
  }

  .Counter11-box {
    width: 48%; /* Set boxes to take 48% width on tablets */
    min-width: 150px;
  }

  .Counter11 {
    font-size: 1.8em;
  }

  .Counter11-text {
    font-size: 0.9em;
  }

  .uppHead{
    margin-bottom: 30%;
    font-size: 2vh;
    margin-top: 12%;
    height: 6vh;
  }
}
@media (max-width: 900px) {
  .service8-section {
    flex-direction: column; /* Stack images and text on smaller screens */
    align-items: center; /* Center items */
    margin-bottom: 30px; /* Increase space between stacked sections */
}

.service8-img-container,
.service8-text {
    width: 100%; /* Full width on smaller screens */
    max-width: 100%; /* Ensure containers use full width */
    padding: 10px; /* Adjust padding for smaller screens */
}

.Counter11-section {
  gap: 15px; /* Increase the gap for better spacing */
}

.Counter11-box {
  width: 48%; /* Set boxes to take 48% width on tablets */
  min-width: 150px;
}

.Counter11 {
  font-size: 1.8em;
}

.Counter11-text {
  font-size: 0.9em;
}

.uppHead{
  margin-bottom: 30%;
  font-size: 2vh;
  margin-top: 12%;
  height: 6vh;
}
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .service-section {
      flex-direction: column; /* Stack images and text on smaller screens */
      align-items: center; /* Center items */
      margin-bottom: 30px; /* Increase space between stacked sections */
  }
  
  .service-img-container,
  .service-text {
      width: 100%; /* Full width on smaller screens */
      max-width: 100%; /* Ensure containers use full width */
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .counters0-section {
    gap: 15px; /* Increase the gap for better spacing */
  }

  .Counter0-box {
    width: 48%; /* Set boxes to take 48% width on tablets */
    min-width: 150px;
  }

  .Counter0 {
    font-size: 1.8em;
  }

  .Counter0-text {
    font-size: 0.9em;
  }

  .uppHead{
    margin-bottom: 20%;
    font-size: 14px;
    margin-top: 25%;
    height: 20vh;
    margin-top: 3%;
  }
.UpTex{
  margin-top: -60px;
}

}

/* Media query for very small screens (mobile) */
/* Media query for very small screens (mobile) */
@media (max-width: 576px) {
  .uppHead {
    margin-bottom: 20%;
    font-size: 8vh;
    margin-top: 25%;
    font-size: 12px;
    width: 70%;
    margin-left: 15%;

  }
  
  .UpTex {
    margin-top: -60px;
  }

  .container-custom {
    padding-top: 10%;
  }

  .service-section {
    margin-bottom: 20px; /* Further reduce spacing for mobile */
  }
  
  .service-img-container {
    width: 100%; /* Full width for images */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
  }

  .service-img {
    width: 100%; /* Full width for images */
    margin-left: 0; /* Remove margin */
  }

  .service-text {
    width: 100%; /* Full width for text */
    padding: 0; /* Remove padding */
    font-size: 4vw; /* Reduce padding */
  }

  .Counter0-box {
    width: 100%; /* Set boxes to take full width on small devices */
  }

  .Counter0 {
    font-size: 1.5em;
  }

  .Counter0-text {
    font-size: 0.8em;
  }

  .second-section {
    flex-direction: column-reverse; /* Reverse order of image and text */
  }
  .UpTex {
    margin-top: -10px;
    padding: 10px 8px 18px 45px;
    font-size: x-large;
  }
}

