/* Container for the entire section */
.container-custom {
  background-color: #d3d3d3; 
  padding: 4% 0; 
  overflow: hidden;
  border-radius: 30%;
}


/* Container for the service rectangle */
.service0-rectangle {
  display: flex;
  flex-direction: column;
  background-color: #f6f8f8; /*#d3d3d3; /* Light grey background color */
  margin: 100px auto;  /* Center the rectangle and add margin */
  width: 80%; /* Rectangle width */
  box-sizing: border-box;
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Hide any overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.racHead2{
  font-family: initial;
  background-color:hwb(180 72% 24%) ;
  width: 100%;
  padding: 22px 15px 12px 8px;
}
.racH2{
  margin-left: 32%;
  color: #1D1D1D;
}
/* Container for each service section inside the rectangle */
.service0-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Each section takes full width of the rectangle */
  margin-bottom: 20px; /* Space between sections */
  padding: 10px; /* Added padding for each service section */
  box-sizing: border-box;
}

/* Reverse order if necessary */
.service0-section .order-last {
  order: 2; /* Move to last order within flex */
}

/* Container for the service image */
.service0-img-container {
  flex: 0 0 45%; /* Adjust the flex basis to 45% */
  max-width: 45%; /* Ensure max-width is 45% */
  padding: 10px; /* Padding around the image container */
  box-sizing: border-box; /* Include padding in the width */
  margin-left: 40px;
}

/* Style for the service image */
.service0-img {
  width: 78%; /* Make the image take full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers its container proportionally */
  border-radius: 10px; /* Add slight rounding to the images */
  margin-left: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation properties for hover */
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Hover effect for service images */
.service0-img:hover {
  transform: scale(1.05); /* Slightly scale up the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a soft shadow */
}
.serviceGif11{
  width: 100%;
}
/* Style for the service text */
.service0-text {
  flex: 0 0 50%; /* Adjust the flex basis to 55% */
  max-width: 50%; /* Ensure max-width is 55% */
  padding: 20px; /* Added padding inside the text container */
  color: #0a0909;
  text-align: left; /* Align text to the left */
  box-sizing: border-box; /* Include padding in the width */
  font-size: larger;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* Style for the button */
.btn-light {
  background-color: #005C74;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px; /* Added padding for button */
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px; /* Margin above button */
}

/* Button hover state */
.btn-light:hover {
  background-color: #000; /* Change background color to black */
  color: #fff; /* Change text color to white */
}
.counters7-section {
  display: flex;
  justify-content: space-between;
 
  padding: 10px 0;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  gap: 10px;
  flex-wrap: wrap; /* Add flex-wrap for smaller screens */
}

/* Individual counter boxes */
.Counter7-box {
  background-color: #005C74;
  color: #fff;
  flex: 1; /* Allows boxes to adjust based on screen size */
  min-width: 200px; /* Set a minimum width for each box */
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px; /* Add space between rows on small screens */
}

/* Hover effect for counter boxes */
.Counter7-box:hover {
  transform: scale(1.05);
}

/* Counter number styling */
.Counter7 {
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 10px;
}

/* Text below the counter */
.Counter7-text {
  font-size: 1em;
  margin-top: 10px;
}

/* Media query for responsiveness */
@media (max-width: 900px) {
  .uppHead{
    margin-bottom: 10%;
    font-size: 2vh;
    margin-top: 10%;
  }
  .service0-img {
    width: 100%; /* Full width for images */
    margin-left: 0; /* Remove margin */
  }
  
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .service0-section {
      flex-direction: column; /* Stack images and text on smaller screens */
      align-items: center; /* Center items */
      margin-bottom: 30px; /* Increase space between stacked sections */
  }
  
  .service0-img-container,
  .service0-text {
      width: 100%; /* Full width on smaller screens */
      max-width: 100%; /* Ensure containers use full width */
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .counters7-section {
    gap: 15px; /* Increase the gap for better spacing */
  }

  .Counter7-box {
    width: 48%; /* Set boxes to take 48% width on tablets */
    min-width: 150px;
  }

  .Counter7 {
    font-size: 1.8em;
  }

  .Counter7-text {
    font-size: 0.9em;
  }

  .uppHead{
    margin-bottom: 30%;
    font-size: 2vh;
    margin-top: 20%;
  }
  .second-section {
    flex-direction: column-reverse; /* Reverse order of image and text */
  }
}

/* Media query for very small screens (mobile) */
@media (max-width: 576px) {
  .uppHead{
    margin-bottom: 30%;
    font-size: 2vh;
    margin-top: 20%;
  }

  .container-custom{
    padding-top: 10%;
  }
  .service0-section {
    margin-bottom: 20px; /* Further reduce spacing for mobile */
  }
  
  .service0-img-container {
    width: 100%; /* Full width for images */
    margin-left: 0; /* Remove margin */
    margin-top: 30%;
  }

  .service0-img {
    width: 100%; /* Full width for images */
    margin-left: 0; /* Remove margin */
  }

  .service0-text {
    width: 100%; /* Full width for text */
    padding: 10px; 
    font-size: 4vw;/* Reduce padding */
  }

  .Counter7-box {
    width: 100%; /* Set boxes to take full width on small devices */
  }

  .Counter7 {
    font-size: 1.5em;
  }

  .Counter7-text {
    font-size: 0.8em;
  }
  .second-section {
    flex-direction: column-reverse; /* Reverse order of image and text */
  }
}

