/* Container for the Care Facility section */
.care-facility-container {
    width: 100%;
    height: 150%;
    background-color: white; /* White background for the entire section */
    padding: 100px 0; /* Add padding for spacing */
}

/* Header section styling */
.header-section {
    padding: 6%;
   text-align: center;
}
.header-section3{
    padding: 8%;
    margin-left: 8rem;
    margin-bottom: -7rem;
}

.header-title {
    font-size: 3em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #005C74;; /* Black color for the header */
    margin-bottom: 10px;
}

.header-subtitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #005C74; /* EMR in color */
}

/* Description text styling */
.description {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 50px;
    color: #000; /* Black color for the description */
    align-content: center;
}

/* Box container styling */
.box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 4%;
   

}


.box-container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 100px;
    padding:"20px"
}
.box-container2 .box {
    flex-basis: calc(25% - 20px); /* Decrease the width to 45% per box */
    box-sizing: border-box; /* Include padding and border in width */
}


/* Individual box styling */
/* Individual box styling */
/* Individual box styling */
/* Individual box styling */
.box {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);  /* Light blue background for boxes */
    width: 300px; /* Adjusted width for three boxes in a row */
    height: 180px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    opacity: 1; /* No fade-in animation on page load */
    transition: transform 0.5s ease, box-shadow 0.5s ease, opacity 0.5s ease;
}

/* Hover effect for smooth transition */
.box:hover {
    transform: translateY(-10px); /* Slight move up on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
    opacity: 0.9; /* Slightly reduce opacity for a smoother effect */
    transition: transform 0.5s ease, box-shadow 0.5s ease, opacity 0.5s ease;
}

/* Box text styling */
.box-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: white; /* White text color */
    font-weight: 600; /* Medium bold text */
    padding: 15%;
}
.box2 {
    background: linear-gradient(45deg, #005C74, #018C92, #01A09E);  /* Light blue background for boxes */
    width: 300px; /* Adjusted width for three boxes in a row */
    height: 180px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    opacity: 1; /* No fade-in animation on page load */
    transition: transform 0.5s ease, box-shadow 0.5s ease, opacity 0.5s ease;
}

/* Hover effect for smooth transition */
.box2:hover {
    transform: translateY(-10px); /* Slight move up on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
    opacity: 0.9; /* Slightly reduce opacity for a smoother effect */
    transition: transform 0.5s ease, box-shadow 0.5s ease, opacity 0.5s ease;
}
.emr-highlight{
    font-weight: bold;
}

.box-title2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: white; /* White text color */
    font-weight: 600; /* Medium bold text */
    padding: 15%;
}
/* Button container styling */
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 1.5%;
}
.button-container2 {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    padding: 3%;
}
/* .button-container3 {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    padding: 3%;
    margin-left: -40%;
} */
.button-container5 {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 1%;
    margin-left: -20rem;
}

/* Updated button styling */
.btn-book-demo, .btn-know-more {
    padding: 15px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-book-demo {
    background-color: #005C74; /* Color for "Book A Demo" */
    color: white;
}

.btn-know-more {
    background-color: #bcedd2; /* Color for "Know More" */
    color: #005C74;
}

.btn-book-demo:hover {
    background-color: #000000; /* Slightly darker on hover */
}

.btn-know-more:hover {
    background-color: #000000; /* Slightly lighter black on hover */
    color: white;
}
/* Container styling */
.grey-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 75%;
    margin: 0 auto;
    background-color: #f0f0f0; /* Adjust background color */
    padding: 30px;
    border-radius: 10px;
    margin-top: 3%;
}

.text-content {
    max-width: 60%; /* Control the width for text content */
}

.text-header {
    margin-bottom: 7%; /* Space between header and description */
}

.text-heading5 {
    font-size: 3rem; /* Adjust for larger screens */
    color: #005C74; /* Color for the heading */
}

.text-description2 {
    font-size: 1.8rem; /* Adjust for larger screens */
    margin-bottom: 6%;
    width: 70%; /* Allow full width for better alignment */
}

.button-container3 {
    display: flex;
    justify-content: start;
    gap: 15px; /* Space between buttons */
    margin-bottom: 4%;
}
.image-content {
    max-width: 100%; 
    max-width: auto;/* Decrease the maximum width of the image container */
    margin-top: 07%;
    margin-bottom:0%; /* Keep margin to maximize height */
    width: 50%; /* Set a smaller width for the container */
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: flex-start; /* Align the image at the top */
    height: 80%; /* Decrease height to adjust the image size */
    margin-right: 4%; /* Adjust right margin */
    overflow: hidden; /* Ensure no overflow of image */
}

.care-facility-image {
    width: 70%; /* Use full width of the image container */
    height: auto; /* Maintain aspect ratio */
    max-height: none; /* Allow height to adjust naturally */
    object-fit: cover; /* Ensure image covers the container */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */
}

.care-facility-image:hover {
    transform: scale(1.02); /* Slightly increase size on hover */
}

.btn-book-demo, .btn-know-more {
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    cursor: pointer;
}

.btn-book-demo {
    background-color: #005C74;
    color: #fff;
}

.btn-know-more {
    background-color: #f0f0f0;
    color: #005C74;
    border: 2px solid #005C74;
}

.circle-container {
    width: 350px; /* Circle size */
    height: 350px; /* Circle size */
    border-radius: 70%; /* Make it a circle */
    background-color: #005C74; /* Circle color */
    position: relative; /* For positioning of children */
    overflow: hidden; /* Prevent overflow */
    margin-top: 3%;
    /* transition: transform 0.3s;  */
}

/* .circle-container:hover {
    transform: scale(1.1); 
} */

.circle-image {
    width: 100%; /* Fill the circle */
    height: auto; /* Maintain aspect ratio */
    position: absolute; /* Absolute positioning for layering */
    top: 60%; /* Adjust this value to lower the image */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the image */
    object-fit: contain; /* Ensure the image covers the area */
    z-index: 1; /* Higher z-index to ensure visibility */
}

.circle-image2 {
    width: 100%; /* Fill the circle */
    height: auto; /* Maintain aspect ratio */
    position: absolute; /* Absolute positioning for layering */
    top: 70%; /* Adjust this value to lower the image */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the image */
    object-fit: contain; /* Ensure the image covers the area */
    z-index: 1; /* Higher z-index to ensure visibility */
}




/* Updated heading styling */
.header-title3 {
    font-size: 3.5em; /* Increase the size of the heading */
    margin-bottom: 30px;
    text-align: left; /* Align the heading to the left */
    width: 130%; /* Ensure full width for alignment */
    padding-left: 1%; /* Add some padding on the left for spacing */
    margin-left: -6vw;
    color: #005C74;
}
.header-title {
    font-size: 3.5em; /* Increase the size of the heading */
    margin-bottom: 30px;
    text-align: left; /* Align the heading to the left */
    width: 100%; /* Ensure full width for alignment */
    padding-left: 8%; /* Add some padding on the left for spacing */
}
/* Text-Image Section Styling */


/* Text Content Styling */


/* Image Content Styling */
/* .image-content {
    width: 55%; 
    text-align: right;
    padding-right: 5%; 
} */



.care-facility-image2 {
    width: 150%;
    height: auto;
    max-width: none;
    transition: transform 0.3s ease;
    margin-left: -35%;
    margin-top: -20%;
}
.care-facility-image2:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
}
/* Button styling */
.button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding-top: 2%;
}
.button-container2 {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding-top: 2%;
    margin-left: -12vw;
}

.btn-book-demo, .btn-know-more {
    padding: 15px 20px;
    font-size: 1.2em; /* Increase font size */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-book-demo {
    background-color: #005C74;
    color: white;
}

.btn-know-more {
    background-color: #bcedd2;
    color: #005C74;
}

.btn-book-demo:hover, .btn-know-more:hover {
    background-color: #000000; /* Change color on hover */
}
.text-description{
    font-size: 1.8em; /* Slightly smaller text for description */
    line-height: 1.5;
    color: #000; /* Black color for the description */
    width:50%;
    margin-left: -1%;
}
/* Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}



/* .text-content-linebreak {
    width: 10%; 
    text-align: left; 
    padding-left: 3%;
    white-space: pre-line
} */

/* .text-heading {
    font-size: 2.3em; 
    margin-bottom: 20px;
    color: #000; 
    align-items: center;
} */



/* Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.text-image-section2 img {
    width: 150%; /* Increase the size of the image */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure the image doesn't overflow its container */
}
.text-image-section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%; /* 10% gap between text and image */
    margin-top: 50px; /* Space above the text-image section */
    width: auto;
}

/* .text-content-linebreak {
    width: 10%; 
    text-align: left; 
    padding-left: 3%;
    white-space: pre-line
} */

.text-heading2 {
    font-size: 3.5em; /* Increase the size of the heading */
    margin-bottom: 30px;
    text-align: left; /* Align the heading to the left */
    width: 100%; /* Ensure full width for alignment */
    padding-left: 2%;
    font-family: initial;
    color: #005C74; /* EMR in color */
}
/* .text-heading5 {
    font-size: 4em; 
    margin-bottom: 30px;
    text-align: left; 
    width: 50%; 
    padding-left: 2%;
    font-family: initial;
    color: #005C74;
    margin-top: 12%;
    margin-left: 5%;
    font-size: 4rem;
} */

/* .text-description2 {
    font-size: 2em; 
    line-height: 1.5;
    color: #000; 
    width:70%;
    margin-left: 4%;
    
} */
.text-description5 {
    font-size: 1.8em; /* Slightly smaller text for description */
    line-height: 1.5;
    color: #000; /* Black color for the description */
    width:90%;
    margin-left: 4%;
    
}
.text-description3 {
    font-size: 2em; /* Slightly smaller text for description */
    line-height: 1.5;
    color: #000; /* Black color for the description */
    width:70%;
    margin-left: 4%;
    
}
.image-content2 {  
    margin-top: -75px; 
}


/* Banner Container */
/* .banner-container {
    background-color: #EDEFF2;
    width: 80%;
    height: 47.5rem; 
    margin: 0 auto; 
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
} */
.banner-container5 {
    background-color: #EDEFF2;
    width: 80%;
    height: 40rem; /* Default for desktop */
    margin: 0 auto; /* Center the banner horizontally */
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Text-Image Section */
.text-image-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5%; /* Reduced gap between text and image */
    margin-top: -50px;
    width: 105%;
}

/* Text Content Styling */
.text-description {
    font-size: 1.8em; /* Large text for desktop */
    line-height: 1.5;
    color: #000;
    width: 80%;
    text-align: left;
    padding-left: 5%; /* Padding on the left */
}

.image-content {
    width: 120%; /* Image section width for desktop */
    text-align: right;
    padding-right: 1%;
}
.image-content2 {
    width: 120%; /* Image section width for desktop */
    text-align: right;
    padding-right: 1%;
}
/* .care-facility-image {
    width: 120%;
    height: auto;
    transition: transform 0.3s ease;
    margin-left: -30%;
    margin-top: -15%;
} */

.care-facility-image:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
}

/* Responsive styles */
@media (min-width:940px)and (max-width:1024px) {
  
    .image-content2{
        width:700px;
        margin-left: -15%;
    }
  
    
}

/* Tablet Screens (up to 1024px) */
@media (max-width: 1024px) {
    .banner-container {
        width: 90%; /* Slightly reduce width for tablets */
        height: auto; /* Allow height to adjust based on content */
        padding: 30px;
    }

    .text-image-section {
        flex-direction: column; /* Stack text and image vertically */
        align-items: center; /* Center align items */
        gap: 2rem; /* Add more space between text and image */
    }

    .text-description2{
        width: 70%;
    margin-left: 15%;
    font-size: 4.5vw;
      }
      .text-description3{
        width: 70%;
    margin-left: 15%;
    font-size: 4.5vw;
      }
    .image-content {
        width: 100%; /* Full width for image */
        text-align: center; /* Center the image */
        padding-right: 0;
    }

    .care-facility-image {
        width: 60%;
        margin-top: .1%;
    }
    .care-facility-image2{
        width: 65%;
        margin-top: .1%;
        margin-right: 22%;
    }
    .button-container {
        margin-left: 0; /* Center buttons */
        text-align: center;
    }
    .button-container2 {
        padding-left: 0% ;/* Center buttons */
        text-align: center;
    }
    .button-container3 {
        padding-left: 10% ;/* Center buttons */
        text-align: center;
        margin-left: -25%;
    }
    .button-container5{
        
        padding-left: 10%;
        
    }
    .text-heading5{
        width: 82%;
        font-size: 8vw;
        margin-left: 12%;
        
    }
    .header-title{
        font-size: 6vw;
    margin-left: 1%;;
    }
    .header-title2{
        width: 150%;
        margin-left: 3vw;
        font-size: 10vw;
        color: #005C74;
    }
    .header-title3{
        font-size: 6.5vw;
    margin-bottom: 14%;
    }
}

/* Mobile Screens (up to 768px) */
@media (max-width: 768px) {
    .banner-container {
        width: 95%; /* Slightly reduce width for mobile */
        padding: 20px;
    }

    .text-image-section {
        flex-direction: column; /* Stack text and image vertically */
        align-items: center;
        gap: 1.5rem;
        margin-top: 0; /* Remove negative margin */
    }

    .text-description2{
        width: 70%;
    margin-left: 10%;
    font-size: 4vw;
      }
      .text-description3{
        width: 70%;
        margin-left: 10%;
        font-size: 4vw;
      }

    .image-content {
        width: 60%; /* Full width for image */
        text-align: center;
    }

    .care-facility-image {
        width: 90%; /* Smaller image for mobile */
    }
    .care-facility-image2{
        width: 60%;
        margin-top: .3%;
        margin-right: 23%;
    }
    .button-container {
        margin-left: 0;
        text-align: center;
        margin-top: 7%;
    }
    .button-container2 {
        padding-left: 0% ;/* Center buttons */
        text-align: center;
    }
    .button-container3 {
        padding-left: 10% ;/* Center buttons */
        text-align: center;
       margin-left: -26vh;
    }
    .btn-book-demo,
    .btn-know-more {
        font-size: 1rem; /* Adjust button font size for mobile */
    }
    .text-heading5{
        width: 82%;
        font-size: 8vw;
        margin-left: 12%;
        
    }
    .header-title{
        font-size: 7vw;
    margin-left: 1%;
    }
    .header-title2{
        width: 150%;
        margin-left: 3vw;
        font-size: 10vw;
        color: #005C74;
    }
    .header-section3{
        margin-bottom: 1%;
     }
     .header-title3{
        margin-left: -60px;
        font-size: 7vw;
  }
     
     .button-container5{
        
        padding-left: 30%;
    }
    .image-content2 {
        /* width: 55%; /* Increase the width of the image content */
        /* text-align: right; */ 
        padding-right: 5%;
    }
    .care-facility-image{
        width: 90%;
  margin-top: 3%;
  margin-right: 20%;
    }
}

/* Small Mobile Screens (up to 480px) */
@media (max-width: 480px) {
    .banner-container {
        width: 100%; /* Full width for small mobile screens */
        padding: 14px;
    }
   

    .care-facility-image {
        width: 100%; /* Full width for small mobile */
    margin-left: 1%;
    }
    .button-container2 {
        padding-left: 0% ;/* Center buttons */
        text-align: center;
    }
    .button-container3 {
        padding-left: 10% ;/* Center buttons */
        text-align: center;
       margin-left: 1vh;
    }
    .header-section3{
        margin-bottom: -1%;
     }
     .header-title3{
        margin-left: -80px;
        font-size: 7vw;
     }
    .image-content2{
width: 100%;
    }
    .text-heading5{
        width: 82%;
        font-size: 10vw;
        margin-left: 12%;
        
    }
    .header-title{
        font-size: 7vw;
        margin-left: 5%;
    }
    .header-title2{
        width: 150%;
        margin-left: 3vw;
        font-size: 10vw;
        color: #005C74;
    }
    .button-container5{
        
        padding-left: 80%;
    }

    .text-description2{
        width: 100%;
        margin-left: 4%;
        font-size: 6vw;
      }
      .text-description3{
        width: 100%;
    margin-left: 4%;
    font-size: 6vw;
      }
    .header-title{
        padding-left: 1%;
    }
    .text-description-linebreak {
        width: 100%; /* Text content occupies 50% of the width */
        
    }
    .care-facility-image2{
        width: 100%;
        margin-top: -2px;
        margin-left: 2%;
    }
}

/* Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
/* Base styles remain the same */

/* Responsive adjustments */
@media (max-width: 1100px) {
    .grey-box {
        flex-direction: column;
        padding: 20px;
    }

    .text-content {
        max-width: 90%;
        margin: 0 auto;
    }

    .text-heading5 {
        font-size: 3.5rem;
    }

    .text-description2 {
        font-size: 2rem;
        width: 100%; 
    }

    .button-container3 {
        flex-direction: row;
        align-items: flex-start;
    }

    .image-content {
        width: 70%; 
        margin-top: 3%; 
    }

    .circle-container {
        width: 300px; 
        height: 300px; 
    }
}

@media (max-width: 1030px) {
    .grey-box {
        width: 90%;
        padding: 15px;
    }

    .text-heading5 {
        font-size: 3rem;
    margin-left: 20%;
    }

    .text-description2 {
        font-size: 2rem;
        width: 60%;
        margin-left: 22%;
    }

    .circle-container {
        width: 200px; 
        height: 200px; 
        margin-left: 10%;
        
    }

    .circle-image {
        top: 60%; /* Adjust image position for smaller screens */
    }
    .button-container3 {
        display: flex;
        justify-content: flex-start;
        align-items: center; /* Align buttons in the same line */
        gap: 15px; /* Space between buttons */
        margin-bottom: 2%;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        padding: 1%;
        margin-left: -20%;
        margin-top: -2%;
    }
    .btn-book-demo, .btn-know-more {
        padding: 15px 15px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
    .box-container2 {
        margin-bottom: 10%;
            }
}

@media (max-width: 768px) {
    .grey-box {
        width: 90%;
        padding: 15px;
    }

    .text-heading5 {
        font-size: 2.5rem;
        margin-left: 20%;
    }

    .text-description2 {
        font-size: 1.5rem;
        width: 60%;
        margin-left: 22%;
    }

    .circle-container {
        width: 150px; 
        height: 150px; 
        margin-left: 40%;
        
    }

    .circle-image {
        top: 60%; /* Adjust image position for smaller screens */
    }
    .button-container3 {
        display: flex;
        justify-content: flex-start;
        align-items: center; /* Align buttons in the same line */
        gap: 15px; /* Space between buttons */
        margin-bottom: 2%;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        padding: 1%;
        margin-left: -4%;
        margin-top: -2%;
    }
    .btn-book-demo, .btn-know-more {
        padding: 12px 12px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
    .box-container2 {
        margin-bottom: 10%;
            }
}

@media (max-width: 576px) {
    .text-heading5 {
        font-size: 1.5rem;
        margin-left: 1%;
        width: 100%;
      
    }

    .text-description2 {
        font-size: 0.9rem;
        width: 90%;
        margin-left: 1%;
    }

    .circle-container {
        width: 150px; 
        height: 150px; 
    }

    .circle-image {
        top: 60%; /* Adjust image position for smaller screens */
    }
    .button-container3 {
        display: flex;
        justify-content: flex-start;
        align-items: center; /* Align buttons in the same line */
        gap: 15px; /* Space between buttons */
        margin-bottom: 2%;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        padding: 1%;
    }
    .btn-book-demo, .btn-know-more {
        padding: 10px 10px;
        font-size: .7em;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
    .box-container2 {
margin-bottom: 10%;
    }
}

/* Responsive design */
