/* Container for the entire section */
.container-custom {
    background-color: #d3d3d3; /*#f6f8f8; /* Background color */
    padding: 4% 0; /* Adjusted padding */
    overflow: hidden;
  }
  

  /* Container for the service rectangle */
  .service6-rectangle {
    display: flex;
    flex-direction: column;
    background-color: #f6f8f8; /*#d3d3d3; /* Light grey background color */
    margin: 100px auto;  /* Center the rectangle and add margin */
    width: 80%; /* Rectangle width */
    box-sizing: border-box;
    border-radius: 15px; /* Rounded corners */
    overflow: hidden; /* Hide any overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .racHead4{
    font-family: initial;
    background-color:hwb(180 72% 24%) ;
    width: 100%;
    padding: 22px 15px 12px 8px;
  }
  .racH4{
    margin-left: 35%;
    color: #1D1D1D;
  }
  /* Container for each service section inside the rectangle */
  .service6-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Each section takes full width of the rectangle */
    margin-bottom: 20px; /* Space between sections */
    padding: 10px; /* Added padding for each service section */
    box-sizing: border-box;
  }
  
  /* Reverse order if necessary */
  .service6-section .order-last {
    order: 2; /* Move to last order within flex */
  }
  
  /* Container for the service image */
  .service6-img-container {
    flex: 0 0 45%; /* Adjust the flex basis to 45% */
    max-width: 45%; /* Ensure max-width is 45% */
    padding: 10px; /* Padding around the image container */
    box-sizing: border-box; /* Include padding in the width */
    margin-left: 40px;
  }
  
  /* Style for the service image */
  .service6-img {
    width: 68%; /* Make the image take full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers its container proportionally */
    border-radius: 10px; /* Add slight rounding to the images */
    margin-left: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation properties for hover */
  }
  
  /* Hover effect for service images */
  .service6-img:hover {
    transform: scale(1.05); /* Slightly scale up the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a soft shadow */
  }
  
  /* Style for the service text */
  .service6-text {
    flex: 0 0 50%; /* Adjust the flex basis to 55% */
    max-width: 50%; /* Ensure max-width is 55% */
    padding: 20px; /* Added padding inside the text container */
    color: #0a0909;
    text-align: left; /* Align text to the left */
    box-sizing: border-box; /* Include padding in the width */
    font-size: larger;
  }
  
  /* Style for the button */
  .btn-light {
    background-color: #005C74;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 20px; /* Added padding for button */
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px; /* Margin above button */
  }
  
  /* Button hover state */
  .btn-light:hover {
    background-color: #000; /* Change background color to black */
    color: #fff; /* Change text color to white */
  }
  
  /* Container for the counters section */
  .Counter9-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    gap: 10px;
    flex-wrap: wrap; /* Add flex-wrap for smaller screens */
  }
  
  /* Individual counter boxes */
  .Counter9-box {
    background-color: #005C74;
    color: #fff;
    flex: 1; /* Allows boxes to adjust based on screen size */
    min-width: 200px; /* Set a minimum width for each box */
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px; /* Add space between rows on small screens */
  }
  
  /* Hover effect for counter boxes */
  .Counter9-box:hover {
    transform: scale(1.05);
  }
  
  /* Counter number styling */
  .Counter9 {
    font-size: 2em; /* Adjust font size as needed */
    margin-bottom: 10px;
  }
  
  /* Text below the counter */
  .Counter9-text {
    font-size: 1em;
    margin-top: 10px;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 900px) {
    .uppHead{
      margin-bottom: 10%;
      font-size: 2vh;
      margin-top: 10%;
    }
    .service6-img {
      width: 100%; /* Full width for images */
      margin-left: 0; /* Remove margin */
    }
   
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .service6-section {
        flex-direction: column; /* Stack images and text on smaller screens */
        align-items: center; /* Center items */
        margin-bottom: 30px; /* Increase space between stacked sections */
    }
    
    .service6-img-container,
    .service6-text {
        width: 100%; /* Full width on smaller screens */
        max-width: 100%; /* Ensure containers use full width */
        padding: 10px; /* Adjust padding for smaller screens */
    }
  
    .counters8-section {
      gap: 15px; /* Increase the gap for better spacing */
    }
  
    .Counter9-box {
      width: 48%; /* Set boxes to take 48% width on tablets */
      min-width: 150px;
    }
  
    .Counter9 {
      font-size: 1.8em;
    }
  
    .Counter9-text {
      font-size: 0.9em;
    }
  
    .uppHead{
      margin-bottom: 30%;
      font-size: 2vh;
      margin-top: 20%;
    }
   
  }
  
  /* Media query for very small screens (mobile) */
  @media (max-width: 576px) {
    .uppHead{
      margin-bottom: 30%;
      font-size: 2vh;
      margin-top: 20%;
    }
  
    .container-custom{
      padding-top: 10%;
    }
    .service6-section {
      margin-bottom: 20px; /* Further reduce spacing for mobile */
    }
    
    .service6-img-container {
      width: 100%; /* Full width for images */
      margin-left: 0; /* Remove margin */
      margin-top: 30%;
    }
  
    .service6-img {
      width: 100%; /* Full width for images */
      margin-left: 0; /* Remove margin */
    }
  
    .service6-text {
      width: 100%; /* Full width for text */
      padding: 10px; 
      font-size: 4vw;/* Reduce padding */
    }
  
    .Counter9-box {
      width: 100%; /* Set boxes to take full width on small devices */
    }
  
    .Counter9 {
      font-size: 1.5em;
    }
  
    .Counter9-text {
      font-size: 0.8em;
    }
    .second-section {
      flex-direction: column-reverse; /* Reverse order of image and text */
    }
    .service11-rectangle {
      display: flex;
      flex-direction: column;
      background-color: #f6f8f8;
      margin: 10px auto;
      width: 95%;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    .service11-img {
      width: 100%;
      margin-left: -13%;
    }
  }