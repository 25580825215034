/* Container for the whole component */
.our-partner-container {
  width: 100%;
  height: 280px; /* Set the height for the marquee */
  background-color: #ace7e6;
  padding: 20px 0;
  overflow: hidden; /* Hide the overflow to create the scrolling effect */
  position: relative;
}

/* Heading style */
.our-partner-heading {
  font-size: 2.5rem;
  color: #005C74;
  margin-bottom: 1px;
  text-align: center;
  font-family: "initial";
}

/* Marquee wrapper */
.marquee {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Marquee content (images) */
.marquee-content {
  display: flex;
  align-items: center;
  width: calc(200%); /* Adjust width to twice the container width */
  animation: scroll-marquee 20s linear infinite; /* Infinite scrolling animation */
}

/* Partner card (each image) */
.partner-card {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

/* Image styling */
.partner-img {
  width: 150px; /* Set the width of the images */
  height: auto;
  object-fit: contain;
}

/* Keyframes for continuous scrolling animation */
@keyframes scroll-marquee {
  0% {
    transform: translateX(0); /* Start with images in their initial position */
  }
  100% {
    transform: translateX(-50%); /* Scroll images by 50% of the container width */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .partner-img {
    width: 100px; /* Smaller images for mobile */
  }
}

@media (max-width: 480px) {
  .partner-img {
    width: 80px; /* Even smaller images for very small screens */
  }
}
