.footer__img{
    width: 140px !important;
}

.ms-main-footer {
    padding: 80px 0 0;
    background:url('../Images/footer-bg-img.png') bottom center;
}
.ms-main-footer p {
    font-size: 16px;
}
.ms-main-footer .ms-footer-links a {
    color:#fff;
    font-size: 16px;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
}

.ms-main-footer .ms-footer-links a:before {
    background: #fff;
    position: absolute;
    content: '';
    width: 0%;
    height: 1px;
    left: 0px;
    bottom: 0px;
    transition: all 500ms ease;
}
.ms-main-footer .ms-footer-links a:hover:before {
    width: 100%;
}
.ms-main-footer .ms-seperator {
    width: 70px;
    height: 1px;
    background: #fff;
    margin-bottom: 25px;
}
.ms-main-footer .ms-social-links a {
    background: #fff;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--ms-primary-color);
    margin: 0 3px;
    border: 1px solid #fff;
}
.ms-main-footer .ms-social-links a:hover {
    background: var(--ms-primary-color);
    color: #fff;
}
.ms-bottom-footer {
    border-top: 1px solid rgba(255,255,255,0.2);
}

