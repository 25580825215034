.blog__btn{
    background-color:transparent;
    border: none;
    transition: all 0.1s ease-in-out;
    height: fit-content;
    width: fit-content;
}

.blog__btn:hover{
    transform: scale(1.1);
}

.blog__container{
    margin-top: 150px !important;
}


.blog_paragraph_color{
    color: grey;
    /* font-size: 17px; */
}

@media (max-width:780px){
    
    .blog__container{
        margin-top: 100px !important;
    }

}